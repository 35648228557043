import React from "react";
import tw, {styled} from "twin.macro";
// import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import { Link } from "../../../@gatsbystorefront/.gatsby-theme-storefront-shopify/utils/general.js";
import MainHeader from "../../../@gatsbystorefront/.gatsby-theme-storefront-shopify/custom-sections/Header/main-header.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-primary-900 hover:border-primary-900 hover:text-primary-900 hover:no-underline`}
  }
  ${NavToggle}.closed {
    ${tw`text-primary-900 hover:text-primary-900 hover:no-underline`}
  }
`; 
const Container = styled.div`
  ${tw`relative w-full bg-center bg-cover`}
  background-image: url(${props=>props.background_image});
  background-color: #fff;
  @media (max-width: 767px) {
    background-image: url(${props=>props.background_image_mb});
  }
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-900 opacity-0`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;

const TwoColumn = styled.div`
${tw`px-4 flex justify-between items-center flex-col lg:flex-row`}
padding-top: 14rem;
padding-bottom: 14rem;
@media (max-width: 768px) {
  padding-top: 2rem;
  padding-bottom: 12rem;
}
${props=>props.custom_css}

`
const LeftColumn = tw.div`flex flex-col items-center lg:block w-full`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2`;

const Heading = styled.h1`
  ${tw`text-2xl leading-none text-center text-primary-900 lg:text-left sm:text-3xl lg:text-4xl xl:text-5xl`} 
  font-weight:700;
   
  span {
    ${tw`inline-block mt-4`}
  }
`; 

const SubHeading = styled.h3`
  ${tw`text-2xl leading-none text-center text-primary-900 lg:text-left sm:text-xl lg:text-3xl xl:text-4xl  pt-4`} 
  font-weight:400;
   
  span {
    ${tw`inline-block mt-4`}
  }
`; 
const FocusDiv = styled.div`
${tw`flex flex-col items-start mt-4 text-left w-full`}
`
const Focus = styled.div`
  ${tw`flex flex-row items-start px-0 lg:px-4 mt-4 text-left`}
  img {
    ${tw`w-4 h-4 max-w-full`}
  }
  span{
    ${tw`px-1 text-sm font-medium text-black`}
  }
`;
const SlantedBackground = styled.span`
  ${tw`relative px-4 py-2 -mx-4 text-primary-900`}
  &::before {
    content: "";
    ${tw`absolute inset-0 transform -skew-x-12 bg-primary-900 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-primary-900 border-l-4 border-blue-500 font-medium text-sm`;
const Paragraph = tw.p`max-w-md my-4 text-center md:text-left xl:text-lg text-primary-900 md:my-8 `;
 
// const PrimaryAction = tw.a`mx-2 px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;
const PrimaryAction = styled(Link)`
${tw`px-16 py-3 mx-1 mt-6 md:mt-10 text-sm font-medium transition duration-300 rounded-full shadow sm:text-base sm:mt-1 sm:px-16 sm:py-3 hover:no-underline hocus:bg-primary-900 hocus:text-gray-100 focus:shadow-outline`}
background-color:${
  props=>props.backgroundColor
};
color:${
  props=>props.textColor
};
`
export default (props) => {
  let normalNavLinks = []
  let primaryNavLinks = []

  {props?.content?.nav_links?.forEach && props.content.nav_links.forEach(link => {     
    if (!link.isMain){
      normalNavLinks.push(<NavLink to={link.url}>{link.text}</NavLink>)
    }else{
      primaryNavLinks.push(<PrimaryLink to={link.url}>{link.text}</PrimaryLink>)
    }
 })
}

if (Array.isArray(primaryNavLinks) && primaryNavLinks.length) {
  // array exists and is not empty
}
  const navLinks = [
    <NavLinks key={1}>
      {normalNavLinks}
    </NavLinks>, 
    // <NavLinks key={2}>
    //   {primaryNavLinks}
    // </NavLinks>
  ];

  const primaryActions = props?.content?.buttons?.map((button) =>{
    return(
        <PrimaryAction
        href={button.open_new_tab ? button.button_url : null}
        to={button.open_new_tab ? null : button.button_url}
        target="_blank"
        backgroundColor={button.background_color}
        textColor={button.text_color}
        >
          {button.button_text}
        </PrimaryAction>
    )
})

// console.log('focuses', props?.content)

const focuses = props?.content?.focuses?.map((focus) =>{
  return(
      <Focus>
        <img src={focus.img_src} alt="" />                
        <span>{focus.heading || "Fully Secure"}</span>
      </Focus>
  )
})



return (
    <Container background_image={props?.content?.background_image} background_image_mb={props?.content?.background_image_mb}>
      <OpacityOverlay />
      <HeroContainer>
        {props?.content?.show_header ? <MainHeader bgColor="bg-transparent"/> : null}        
        <TwoColumn custom_css={props?.content?.custom_css}>
          <LeftColumn>
          {props?.content?.notification ? <Notification>{props?.content?.notification}</Notification> : null }
            <Heading>
              <div dangerouslySetInnerHTML={{__html:props?.content?.heading}}></div>
              {/* <SlantedBackground>{props?.content?.subTitle}</SlantedBackground> */}
            </Heading>
            {
            props?.content?.sub_heading ? 
            <SubHeading>
              <div dangerouslySetInnerHTML={{__html:props?.content?.sub_heading}}></div>
            </SubHeading> 
            : 
            null
            }
            <Paragraph  dangerouslySetInnerHTML={{__html:props?.content?.description}} ></Paragraph>
            {props?.content?.show_buttons ? primaryActions : null}
            {focuses ? <FocusDiv>{focuses}</FocusDiv> : null}
          </LeftColumn>
          {props?.content?.right_image && <RightColumn>
             <img src={props?.content?.right_image}></img>
          </RightColumn>}
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
